import React from "react";
import styled from "styled-components";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import {
  Container,
  Content2Xl,
  ContentWithVerticalPadding,
} from "components/misc/Layouts";
import tw from "twin.macro";
import { LogoLink } from "components/headers/light.js";
import { SectionHeading as HeadingBase } from "components/misc/Headings";
import { SectionDescription as DescriptionBase } from "components/misc/Typography";

import { ReactComponent as CheckboxIcon } from "feather-icons/dist/icons/check-circle.svg";
import { ReactComponent as RadioIcon } from "feather-icons/dist/icons/radio.svg";

import heroScreenshotImageSrc from "images/demo/search.jpg";
import logo from "images/logo.svg";

import FiveColumnWithBackgroundFooter from "components/footers/FiveColumnWithBackground.js";
import ThreeColumnWithProfileImageTestimonial from "components/testimonials/ThreeColumnWithProfileImage.js";
import TabCardGrid from "components/cards/TabCardGrid";

import axios from "axios";

const Row = tw.div`flex`;
const NavRow = tw(Row)`flex flex-col lg:flex-row items-center justify-between`;
const Actions = styled.div`
  ${tw`relative max-w-md text-center mx-auto lg:mx-0`}
  input {
    ${tw`sm:pr-48 pl-4 py-2 sm:py-2 rounded-full border-2 w-full font-medium focus:outline-none transition duration-300 focus:border-primary-500 hover:border-gray-500`}
  }
  button {
    ${tw`w-full sm:absolute right-0 top-0 bottom-0 bg-primary-500 text-gray-100 font-bold mr-2 my-4 sm:my-2 rounded-full py-2 flex items-center justify-center sm:w-40 sm:leading-none focus:outline-none hover:bg-primary-900 transition duration-300`}
  }
`;
const HeroRow = tw(
  Row
)`flex-col lg:flex-row justify-between items-center pt-8 lg:pt-12 pb-16 max-w-screen-2xl mx-auto flex-wrap`;
const Column = tw.div`flex-1`;
const UpdateNotice = tw(
  Column
)`w-full flex-auto mb-4 sm:mb-8 rounded px-4 py-3 sm:px-5 sm:py-4 bg-orange-100 text-orange-800 flex items-center sm:items-start md:items-center justify-center lg:justify-start border border-orange-200 text-xs sm:text-sm text-center sm:text-left md:leading-none`;
const UpdateNoticeIcon = tw(RadioIcon)`w-0 sm:w-5 sm:mr-3`;
const TextColumn = tw(
  Column
)`mx-auto lg:mr-0 max-w-2xl lg:max-w-xl xl:max-w-2xl flex-shrink-0`;
const Heading = tw(
  HeadingBase
)`text-center lg:text-left text-primary-900 leading-snug`;
const Description = tw(
  DescriptionBase
)`mt-4 text-center lg:text-left lg:text-base text-gray-700 max-w-lg mx-auto lg:mx-0`;
const Recommendation = tw(
  DescriptionBase
)`mt-1 text-center lg:text-right lg:text-base text-gray-700 max-w-lg mx-auto lg:mx-0`;
const FeatureList = tw.ul`mt-6 leading-loose flex flex-wrap max-w-xl mx-auto lg:mx-0`;
const Feature = tw.li`mt-2 flex items-center flex-shrink-0 w-full sm:w-1/2 justify-center lg:justify-start`;
const FeatureIcon = tw(CheckboxIcon)`w-5 h-5 text-primary-500`;
const FeatureText = tw.p`ml-2 font-medium text-gray-700`;
const ImageColumn = tw(Column)`mx-auto lg:mr-0 relative mt-16 lg:mt-0 lg:ml-8`;
const ImageContainer = tw.div``;
const Image = tw.img`max-w-full rounded-t sm:rounded`;
const SectionContainer = tw(ContentWithVerticalPadding)``;
const SectionHeading = tw(HeadingBase)`text-primary-900`;
const SectionDescription = tw(
  DescriptionBase
)`text-center mx-auto text-gray-600 max-w-4xl`;

export default ({
  features = null,
  heading = "Find proven books on goodreads.",
  description = "Filter by scores and genres to find great books on goodreads. Stop wasting your time browsing, searching, and scrolling tediously.",
}) => {
  features = features || [
    "Rating > 4.0",
    "6 Genres",
    "5K+ Ratings",
    "Quick link to goodreads",
    "100 books per genre",
  ];

  const register = (e) => {
    e.preventDefault();
    const email_dom = e.target.parentNode.elements[0];
    const email = email_dom.value;
    const data = {
      fields: { email },
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer keyll2r92w8MuEIDw",
    };
    axios
      .post("https://api.airtable.com/v0/appnR2r7bYCTlQKst/emails", data, {
        headers,
      })
      .then((res) => {
        if (res.status === 200) {
          email_dom.value = "";
        }
      })
      .catch((err) => {
        console.log(err);
      });
    window.alert("Wonderful! You just looped in :)");
  };

  return (
    <AnimationRevealPage disabled>
      <Container tw="bg-gray-100 -mx-8 -mt-8 pt-8 px-8">
        <Content2Xl>
          <NavRow>
            <LogoLink href="/">
              <img src={logo} alt="" />
              Goodreads Filter
            </LogoLink>
            <Actions>
              <form method="POST">
                <input type="text" name="email" placeholder="Your Email" />
                <button id="submitBtn" onClick={register}>
                  Subscribe
                </button>
              </form>
            </Actions>
          </NavRow>
          <NavRow>
            <Recommendation></Recommendation>
            <Recommendation>
              Subscribe to our newsletter. We'll recommend you a great book
              every month.
            </Recommendation>
          </NavRow>
          <HeroRow>
            <UpdateNotice>
              <UpdateNoticeIcon />
              Last updated: 2024-02-28 10:28 KST (+09:00)
            </UpdateNotice>
            <TextColumn>
              <Heading as="h1">{heading}</Heading>
              <Description>{description}</Description>
              <FeatureList>
                {features.map((feature, index) => (
                  <Feature key={index}>
                    <FeatureIcon />
                    <FeatureText>{feature}</FeatureText>
                  </Feature>
                ))}
              </FeatureList>
            </TextColumn>
            <ImageColumn>
              <ImageContainer>
                <Image src={heroScreenshotImageSrc} />
              </ImageContainer>
            </ImageColumn>
          </HeroRow>
          <SectionContainer id="componentDemos">
            <SectionHeading>Hi there!</SectionHeading>
            <SectionDescription>
              If you love reading books just as much as I do, you might have
              experienced struggling finding a good book. Actually, there are
              lots of books that you don't even know that exist. Don't worry.
              You won't miss them. Just choose your genre, and set a minimum
              rating to find best books for you!
            </SectionDescription>
            <TabCardGrid />
            <ThreeColumnWithProfileImageTestimonial />
          </SectionContainer>
        </Content2Xl>
        <FiveColumnWithBackgroundFooter />
      </Container>
    </AnimationRevealPage>
  );
};
